import service from '@/utils/request.js'

// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/**************************************
 * 调货模块
 **************************************/
/**
 * 获取列表信息
 */
export function cargo_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: 'admin/cargo/list',
        method: 'get',
        params:data
    })
}
