import axios from 'axios'
import {Message} from 'element-ui'
import router from "../router/index";


const service = axios.create({
    // baseURL:'http://121.196.103.197/',  
    baseURL:'https://www.lzljsb.cn/',
    timeout:50000,
});

// 添加请求拦截器
service.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    let token = sessionStorage.getItem('token')
    if(token){
        config.headers.authorization = token;
    }
    if (config.type == 'upload') {
        config.headers['Content-Type'] = 'multipart/form-data;'
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});


// 添加响应拦截器
service.interceptors.response.use(function (response) {
    let data = response.data
    switch (data.code){
        case 200:
            return data;
        case 406:
            Message.error(data.msg);
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('user_info')
            router.replace('/')
            break;
        case 500:
            Message.error(data.msg);
            return data;
        default:
            Message.error(data.msg);
            break;

    }
}, function (error) {

    // 200 success
    // 500 错误
    // 406 登录失效
    // 对响应错误做点什么
    console.log('对响应错误做点什么')
    console.log(Promise.reject(error))
    return Promise.reject(error);
});

export default service;