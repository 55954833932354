import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

/**
 * 普通路由
 */
export const defaultRoutes  = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/layer',
    name: 'Layer',
    component: () => import('../views/layer/index.vue'),
    children:[
      {
        path: '/scope',
        name:'Scope',
        component: () => import('../views/scope/index.vue'),
      },
      {
        path: '/staff',
        name:'Staff',
        component: () => import('../views/staff/index.vue'),
      },
      {
        path: '/role',
        name:'Role',
        component: () => import('../views/role/index.vue'),
      },
      {
        path: '/entrepot',
        name:'Entrepot',
        component: () => import('../views/entrepot/index.vue'),
      },
      {
        path: '/extract',
        name:'Extract',
        component: () => import('../views/extract/index.vue'),
      },
      {
        path: '/shop',
        name:'Shop',
        component: () => import('../views/shop/index.vue'),
      },
      {
        path: '/procurement',
        name:'Procurement',
        component: () => import('../views/procurement/index.vue'),
      },
      {
        path: '/shipment',
        name:'Shipment',
        component: () => import('../views/shipment/index.vue'),
      },
      {
        path: '/inventory',
        name:'Ihipment',
        component: () => import('../views/inventory/index.vue'),
      },

      {
        path: '/record',
        name:'record',
        component: () => import('../views/record/index.vue'),
      },
      {
        path: '/department',
        name:'Department',
        component: () => import('../views/department/index.vue'),
      },


      {
        path: '/shipment',
        name:'Shipment',
        component: () => import('../views/shipment/index.vue'),
      },
      {
        path: '/mediate',
        name:'Mediate',
        component: () => import('../views/mediate/index.vue'),
      },
      {
        path: '/salesman',
        name:'Salesman',
        component: () => import('../views/salesman/index.vue'),
      },
      {
        path: '/promoting',
        name:'Promoting',
        component: () => import('../views/promoting/index.vue'),
      },
      {
        path: '/finance',
        name:'Finance',
        component: () => import('../views/finance/index.vue'),
      },
      {
        path: '/statistics',
        name:'Sromoting',
        component: () => import('../views/statistics/index.vue'),
      },
      {
        path: '/sales',
        name:'Sales',
        component: () => import('../views/sales/index.vue'),
      },
      {
        path: '/account',
        name:'Account',
        component: () => import('../views/account/index.vue'),
      },
      {
        path: '/withdraw',
        name:'Withdraw',
        component: () => import('../views/withdraw/index.vue'),
      } 
    ],
  },
  {
    path: '/404',
    name: 'Notfount',
    component: () => import('../views/404.vue')
  },
  {
    path: '*',
    name: 'Notfount',
    component: () => import('../views/404.vue')
  }
]



const router = new VueRouter({
  mode:'history',
  routes:defaultRoutes 
})

/**
 * 路由守卫
 */
router.beforeEach((to,from,next)=>{
  // next('/login')
  next()
})

export default router
