// 全局过滤器

import Vue from 'vue'
import Vuex from 'vuex'
import store from '../store/index'
Vue.use(Vuex);
 
/* 设置图片地址  1,234.56 */
let replaceUrl = value =>  {
    let ret = store.state.baseUrl + value;
    return ret;
}

let timeFilter = value =>{
    if(value){
        return value.slice(0, 10);
    }else{
        return value
    }
    
}
 
//定义下全局过滤器  往 {}里面加 逗号隔开
export  {replaceUrl,timeFilter}
 