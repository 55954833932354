import service from '@/utils/request.js'

// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/**************************************
 * 商品管理
 **************************************/
/**
 * 商品管理查看
 */
export function index_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/goods/index',
        method: 'post',
        data
    })
}

/**
 * 商品管理删除
 */
export function index_del(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/goods/del',
        method: 'post',
        data
    })
}

/**
 * 商品管理删除
 */
export function editqrcode(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/goods/editqrcode',
        method: 'post',
        data
    })
}

