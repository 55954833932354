<template>
    <div>
        <div class="title">
            <div>角色管理</div>
            <el-button type="success" @click="addOneRoleClick">添加角色</el-button>
        </div>
        <!-- 列表 -->
        <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-background="#fff"
        :data="tableData"
        border
        stripe
        style="width: 100%">
            <el-table-column
                prop="title"
                label="名称">
            </el-table-column>
            <el-table-column
                prop="identity"
                label="身份">
            </el-table-column>
            <el-table-column
                prop="remark"
                label="备注">
            </el-table-column>
            <el-table-column
                label="状态">
                <template slot-scope="scope">
                    <el-tag
                    :type="scope.row.tag == 0 ? 'primary' : 'success'"
                    disable-transitions>{{scope.row.status == 0 ?'禁用':'启用'}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                prop="update_time"
                label="添加时间">
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button
                    size="mini"
                    type="primary"
                    @click="editRoleData(scope.$index, scope.row)">编辑</el-button>
                    <el-button
                    size="mini"
                    type="danger"
                    @click="delRoleData(scope.$index, scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
        class="table-limit"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageArray"
        :page-size= this.limit.page_size
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>


        <el-dialog  
        title="添加角色" 
        :visible.sync="dialogTableVisible"  
        @close="closeClick">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="名称" prop="title">
                    <el-input v-model="ruleForm.title"></el-input>
                </el-form-item>
                <el-form-item label="身份" prop="identity">
                    <el-input v-model="ruleForm.identity"></el-input>
                </el-form-item>
                <el-form-item label="状态" >
                    <el-switch v-model="ruleForm.seeStatus"></el-switch>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                    <el-input v-model="ruleForm.remark"></el-input>
                </el-form-item>
                <el-form-item label="权限">
                    <el-tree
                    :data="tree"
                    show-checkbox
                    node-key="id"
                    ref="tree"
                    highlight-current>
                    </el-tree>
                </el-form-item>
            </el-form>

            
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogTableVisible = false">取 消</el-button>
                <el-button v-if="ButtonStatus == 0" :loading="Buttonloading" type="primary" @click="addRouleClick">确 定</el-button>
                <el-button v-else :loading="Buttonloading" type="primary" @click="addRouleClick">保存</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import { get_role , get_rule_list,add_role,del_rule,see_rule,updata_rule} from '@/api/scope.js'
export default {
    name:'Role',
    data(){
        return {
            pageArray:this.$store.state.pageArray,
            limit:{
                page:1,
                page_size:10, 
            },
            // true 添加 false 修改
            ButtonStatus:0,
            Buttonloading:false,
            loading:true,
            tableData:[],
            total:0,
            currentPage:1,
            dialogTableVisible:false,
            tree:[],
            popuploading:true,
            status:true,
            ruleForm: {
                title: '',
                identity: '',
                seeStatus: true,
                remark: ''
            },
            rules: {
                title: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                identity: [
                    { required: true, message: '请输入身份', trigger: 'blur' }
                ],
                remark: [
                    { required: true, message: '请输入备注', trigger: 'blur' }
                ]
            }
            
        };
        
    },
    created(){
        this.getRoleList()
        this.getALLRoleList()
    },
    methods:{
        // 获取角色列表
        getRoleList(){
            get_role(this.limit).then(res=>{
                this.tableData = res.data.data
                this.total = res.data.total
                this.loading = false
            })
        },

        // 获取全部权限列表
        getALLRoleList(){
            const allScope = this.$store.state.allScope
            if(allScope.length > 0){
                return 
            }
            get_rule_list().then(res=>{
                // 格式化权限，并储存在vuex中
                let data = res.data
                const tree = []
                const allTree = []
                data.forEach(element => {
                    allTree.push(element.id + '')
                    let treeChildren = []
                    if(element.subset != undefined){
                        if(element.subset.length > 0){
                            // 存在子节点 组装二级数据
                            element.subset.forEach( index =>{
                                let children = {
                                    id : index.id,
                                    label : index.title,
                                    status : false
                                }
                                allTree.push(index.id + '')
                                treeChildren.push(children)
                            })
                        }
                    }
                    // 组装一级数据
                    tree.push({
                        id:element.id,
                        label : element.title,
                        status : false,
                        children : treeChildren
                    })
                });
                this.$store.commit('SET_SCOPE', tree);
                this.$store.commit('SET_ALLSCOPE', allTree);
            })
        },

        // 点击添加角色
        addOneRoleClick(){
            this.ButtonStatus = 0
            this.dialogTableVisible = true
            this.tree = this.$store.state.allScope
        },
        //询问删除角色
        delRoleData(index,item){
            this.$confirm('此操作将永久删除  '+ item.title +'  这个角色, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    del_rule({role_id:item.id}).then(res=>{
                        if(res.code == 200){
                            // 确定删除
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getRoleList()
                        }
                    })
                    
                }).catch(() => {         
            });

        },

        // 添加角色 / 修改角色
        addRouleClick(){
            
            if(!this.ruleForm.title){
                this.$message.error('请输入名称');
                return
            }
            if(!this.ruleForm.identity){
                this.$message.error('请输入身份');
                return
            }
            if(!this.ruleForm.remark){
                this.$message.error('请输入备注');
                return
            }
            this.Buttonloading = true
            let data = this.ruleForm
            if(data.seeStatus){
                data.status = 1
            }else{
                data.status = 0
            }
            data.rules = this.$refs.tree.getCheckedKeys()
            if(this.ButtonStatus == 0){
                // 0 添加  非0 修改
                add_role(data).then(res=>{
                    if(res.code == 200){
                        this.dialogTableVisible = false
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.Buttonloading = false
                        this.getRoleList()
                    }
                })
            }else{
                data.role_id = this.ButtonStatus
                updata_rule(data).then(res=>{
                    if(res.code == 200){
                        this.dialogTableVisible = false
                        this.$message({
                            message: res.msg,
                            type: 'success'
                        });
                        this.Buttonloading = false
                        this.getRoleList()
                    }
                })
            }
        },

        // 点击编辑
        editRoleData(index,item){
            var that = this
            that.ButtonStatus = item.id
            that.dialogTableVisible = true
            that.tree = this.$store.state.allScope
            see_rule({role_id:item.id}).then(res=>{
                let data = res.data
                that.ruleForm.title = data.title
                that.ruleForm.identity = data.identity
                that.ruleForm.remark  = data.remark
                if(data.rules == '*'){
                    console.log('**********************')
                    // 拥有全部权限
                    that.$nextTick(() => {
                        that.$refs.tree.setCheckedKeys(this.$store.state.allTree)
                    });
                }else{
                    console.log('**********22222222222222')
                    that.$nextTick(() => {
                        that.$refs.tree.setCheckedKeys(data.rules)
                    });
                }
            })
        },
        // 关闭的回调 重置数据
        closeClick(){
            this.$refs.tree.setCheckedKeys([])
            this.ruleForm.title = ''
            this.ruleForm.identity = ''
            this.ruleForm.remark  = ''
        },
        handleSizeChange(val) {
            this.limit.page_size = val
            this.loading = true
            this.getRoleList()
        },
        handleCurrentChange(val) {
            this.limit.page = val
            this.loading = true
            this.getRoleList()
        }
    }
}

</script>

<style scoped>
.title{
    font-size: 24px;
    font-weight: bold;
    color: #428CFF;
    line-height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
</style>