<template>
    <div>
        <div class="entrepot-title-all">
            <div class="entrepot-title">套账管理</div>
            <el-button class="addButton-class" @click="dialogFormVisible = true " >增加套账</el-button>
        </div>



        <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-background="#fff"
            :data="list"
            border
            stripe
            style="width: 100%">
                <el-table-column
                    prop="id"
                    label="ID">
                </el-table-column>
                <el-table-column
                    label="名字">
                    <template slot-scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.name" ></el-input>
                            <span v-else>{{scope.row.name}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <el-button @click="delClick(scope.row)" type="text" size="small">删除</el-button>
                        <el-button v-if="scope.row.editButton" @click="editClick(scope.$index,scope.row)" type="text" size="small">保存</el-button>
                    </template>
                </el-table-column>
        </el-table>



        <el-dialog title="新增套账" :visible.sync="dialogFormVisible" :destroy-on-close="true" width="40%" >
            <el-form :model="formInline" class="demo-form-inline">
                <el-row :gutter="20">
                    <el-col :span="11">
                        <el-form-item label="套账类型：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.name" placeholder="套账类型"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="11">
                        <el-form-item label="套账单价：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.plane" placeholder="套账单价"></el-input>
                        </el-form-item>
                    </el-col> -->
                </el-row>
                <!-- <el-row :gutter="20">
                    <el-col :span="11">
                        <el-form-item label="折扣：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.name" placeholder="折扣"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="11">
                        <el-form-item label="折扣金额：" label-width="100px">
                            <el-input class="input-all"  v-model="formInline.phone" placeholder="折扣金额"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row> -->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button  :loading="ButtonLoading" type="primary" @click="confirmButtonClick">确 定</el-button>
            </div>
        </el-dialog>





    </div>
</template>

<script>
import { list,save,deletes,update } from '@/api/extract.js'
export default {
    name:'Extract',
    data(){
        return{
            list:[],
            loading:true,
            dialogFormVisible:false,
            ButtonLoading:false,
            formInline: {
                name: ''
            },
        }
    },
    created(){
        this.getList()
    },
    methods:{
        getList(){
            list().then(res=>{
                let data = res.data
                data.forEach(element => {
                    element.editStatus = false
                    element.editButton = false
                });
                this.list = data
                this.loading = false
            })
        },
        //确定添加
        confirmButtonClick(){
            this.ButtonLoading = true
            save(this.formInline).then(res=>{
                this.ButtonLoading = false
                this.dialogFormVisible = false
                this.$message({
                    type: 'success',
                    message: res.msg
                });
                this.getList();
            })
        },
        // 单元格双击
        cellOneClick(row, column, cell, event){
            this.list[row].editStatus = true
            this.list[row].editButton = true
        },

        // 删除
        delClick(row){
            this.$confirm('此操作将永久删除 , 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    deletes({set_meal_id:row.id}).then(res=>{
                        if(res.code == 200){
                            // 确定删除
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getList()
                        }
                    })
                    
                }).catch(() => {         
            });
        },

        // 保存按钮
        editClick(row, column, cell, event){
            let data = {
                set_meal_id:column.id,
                name: column.name,
            }
            this.list[row].editStatus = false
            this.list[row].editButton = false
            update(data).then(res=>{})
        },
    }
}
</script>

<style scoped>
.entrepot-title-all{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.entrepot-title{
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    line-height: 90px;

}
.entrepot-color{
    color: #428CFF;
}
.addButton-class{
    background: linear-gradient(90deg, #FF861B, #FFB527);
    box-shadow: 0px 5px 30px 0px rgba(255, 142, 66, 0.4);
    border-radius: 6px;
    border: none;
    color: #fff;

}
</style>