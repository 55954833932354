<template>
  <div>
    <div class="statistics-all">
      <div class="title">部门管理</div>
      <el-button @click="dialogFormVisible = true">添加部门</el-button>
    </div>
    
    <!-- 表格 -->
    <el-table :data="tableData" stripe style="width: 100%" element-loading-text="拼命加载中" element-loading-background="#fff"  border>
      <el-table-column prop="id" label="ID" width="180" align="center" ></el-table-column>
      
      <el-table-column label="部门" align="center">
        <template scope="scope">
            <div @dblclick="cellOneClick(scope.$index,scope.row)">
                <el-input 
                    v-if="scope.row.editStatus"
                    size="small" 
                    v-model="scope.row.name" ></el-input>
                <span v-else>{{scope.row.name}}</span>
            </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
            <el-button @click="delClick(scope.row)" type="text" size="small">删除</el-button>
            <el-button v-if="scope.row.editButton" @click="editClick(scope.$index,scope.row)" type="text" size="small">保存</el-button>
        </template>
      </el-table-column>
    </el-table>
    


    <el-dialog title="添加部门" :visible.sync="dialogFormVisible" :destroy-on-close="true" width="40%" >
        <el-row :gutter="20">
            <el-col :span="11">
                <el-input class="input-all"  v-model="name" placeholder="请输入部门"></el-input>
            </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button  :loading="ButtonLoading" type="primary" @click="confirmButtonClick">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { get_index,get_edit,get_del,get_add } from "@/api/department.js";
export default {
  name: "Department",
  data() {
    return {
      name:'',
      loading: true,
      tableData: [],
      dialogFormVisible:false,
      ButtonLoading:false,
    };
  },
  created() {
    // 获取首页数据
    this.getinfo();

    // this.getRoleList()

    // this.getbrandList()
  },
  methods: {
    //获取列表信息
    getinfo() {
      get_index(this.limit).then((res) => {
        let data = res.data;
        data.forEach(element => {
            element.editStatus = false
            element.editButton = false
        });
        this.tableData = data;
        this.loading = false;
      });
    },

   
    // 单元格双击
    cellOneClick(row, column, cell, event){
        this.tableData[row].editStatus = true
        this.tableData[row].editButton = true
    },

    // 保存按钮
    editClick(row, column, cell, event){
        let data = {
            id:column.id,
            name: column.name,
        }
        this.tableData[row].editStatus = false
        this.tableData[row].editButton = false
        get_edit(data).then(res=>{})
    },

    // 确定新增按钮
    confirmButtonClick(){
        if(!this.name){
            this.$message.error('请输入部门');
            return
        }
        this.ButtonLoading = true
        get_add({name:this.name}).then(res=>{
            this.dialogFormVisible = false
            this.ButtonLoading = false
            if(res.code == 200){
                this.getinfo()
                this.$message({
                    type: 'success',
                    message: res.msg
                });
            }
        })  
    },

    // 删除
    delClick(row){
        this.$confirm('此操作将永久删除 , 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                get_del({id:row.id}).then(res=>{
                    if(res.code == 200){
                        // 确定删除
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getinfo();
                    }
                })
                
            }).catch(() => {         
        });
    },
  },
};
</script>

<style scoped>
.title {
  width: 120px;
  height: 40px;
  padding-left: 10px;
  font-size: 28px;
  font-weight: 500;
  color: #333333;
  border-left: #418bff solid 8px;
  margin-bottom: 20px;
}
.statistics-all{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:40px
}
</style>