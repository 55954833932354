import router from "./index";

// 导入默认的配置的静态路由
import { defaultRoutes } from "./index"

export const getAsyncRoutes = arr => {
    return arr.map(({path, name, component, meta, children}) => {
      const route = {
        path,
        name,
        // 根据后台返回的 component 的路径，引入路由对应的组件
        component: () => import(`../views/${component}/index.vue`)
      };
      return route;
    });
};

export const setAsyncRoutes = menu => {
    // 路由格式化
    const _menu = getAsyncRoutes(menu);
    console.log(_menu)
    // 将处理好的动态配置的路由通过 vue 提供的方式添加到 router 中，注意这个 _menu 的格式是和配置路由时的键 routes 一样格式的数组
    router.addRoutes(_menu);
    // 路由 options 并不会随着 addRoutes 动态响应，所以要在这里进行设置
    router.options.routes = defaultRoutes.concat(_menu);
};