import service from '@/utils/request.js'
// 导入签名计算
import { encrypt } from '@/utils/encrypt'

/************************************
 * 出库组
 ************************************/


 /**
  * 
  * @param {出货单详情} data 
  */
export function ship_info(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/shipment/info',
        method: 'post',
        params:data
    })
}


 /**
  * 
  * @param {出货单列表} data 
  */
 export function ship_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/shipment/list',
      method: 'get',
      params:data
    })
  }

 /**
  * 
  * @param {出货记录删除} data 
  */
 export function ship_del(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/shipment/dellist',
      method: 'post',
      params:data
    })
  }



   /**
  * 
  * @param {写入获取商品详情} data 
  */
export function ship_write_goods_info(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/shipment/write_goods_info',
      method: 'post',
      params:data
    })
  }

/**
 * 确认出货
 */
export function ship_affirm(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/shipment/affirm',
        method: 'post',
        params:data
    })
}

/**
 * 扫码获取商品详情
 */
export function scan_goods_info(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/shipment/scan_goods_info',
        method: 'post',
        data
    })
}

/**
 * 仓库列表
 */
export function all_warehouse(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/shipment/all_warehouse',
        method: 'get',
        params:data
    })
}


/**
 * 写入获取商品详情
 */
export function write_goods_info(data={}) {
  data.sign = encrypt(data);
  return service.request({
      url: '/admin/shipment/write_goods_info',
      method: 'post',
      params:data
  })
}


