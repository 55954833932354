<template>
    <div>
        <div class="entrepot-title-all">
            <div class="entrepot-title">员工资料</div>
            <div>
                <el-row :gutter="20">
                    <el-col :span="16">
                        <el-input
                            placeholder="输入真实姓名或所属商城搜索"
                            prefix-icon="el-icon-search"
                            v-model="limit.nickname">
                        </el-input>
                    </el-col>
                    <el-col :span="4"><el-button class="addButton-class" @click="searchClick" >搜索</el-button></el-col>
                </el-row>
            </div>
        </div>

        <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-background="#fff"
            :data="tableData"
            border
            stripe
            style="width: 100%">
                <el-table-column
                    align="center"
                    label="员工编号">
                    <template scope="scope">
                        <div>
                            <span>{{scope.row.uid}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="姓名">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.realname" ></el-input>
                            <span v-else>{{scope.row.realname}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="身份证号">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.id_no" ></el-input>
                            <span v-else>{{scope.row.id_no}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="手机号">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.uphone" ></el-input>
                            <span v-else>{{scope.row.uphone}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="微信">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.wechat" ></el-input>
                            <span v-else>{{scope.row.wechat}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="银行卡号">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.card_no" ></el-input>
                            <span v-else>{{scope.row.card_no}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="注册日期">
                    <template scope="scope">
                        <div>
                            <span >{{scope.row.create_time}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="入职商场">
                    <template scope="scope">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                                {{scope.row.name}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="dropdown-all">
                                <el-dropdown-item 
                                    @click.native="selectBottomClick(scope.row,item,scope.$index)" 
                                    v-for="(item,index) in UserList" 
                                    :key="index">{{item.name}}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <!-- <el-table-column
                    label="备注">
                    <template scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                @blur="handleInputBlur" 
                                v-model="scope.row.uid" ></el-input>
                            <span v-else>{{scope.row.uid}}</span>
                        </div>
                    </template>
                </el-table-column> -->
                

                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <el-button @click="delClick(scope.row)" type="text" size="small">删除</el-button>
                        <el-button v-if="scope.row.editButton" @click="editClick(scope.$index,scope.row)" type="text" size="small">保存</el-button>
                    </template>
                </el-table-column>
        </el-table>

        <el-pagination
        class="table-limit"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageArray"
        :page-size= this.limit.page_size
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

    </div>
</template>

<script>
import { index_list , index_del, index_edit,index_admin_list} from '@/api/staff.js'
export default {
    name:'Staff',
    data(){
        return{
            pageArray:this.$store.state.pageArray,
            limit:{
                page:1,
                page_size:10,
                nickname:''
            },
            loading:true,
            tableData:[],
            total:0,
            currentPage:1,
            UserList:[]
            
        }
    },
    created(){
        // 获取首页数据
        this.getlist()
        this.getListUser()
    },
    methods:{
        // 列表
        getlist(){
            index_list(this.limit).then(res=>{
                let data = res.data.data
                if(data != undefined){
                    data.forEach(element => {
                        element.editStatus = false
                        element.editButton = false
                    });
                }
                this.tableData = data
                this.total = res.data.total
                this.loading = false
            })
        },

        // 点击搜索
        searchClick(){
            this.limit.page = 1
            this.getlist()
        },

        getListUser(){
            index_admin_list().then(res=>{
                this.UserList = res.data
            })
        },

        // 删除
        delClick(row){
            this.$confirm('此操作将永久删除 , 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    index_del({uid:row.uid}).then(res=>{
                        if(res.code == 200){
                            // 确定删除
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getlist()
                        }
                    })
                    
                }).catch(() => {         
            });
        },

        // 单元格双击
        cellOneClick(row, column, cell, event){
            this.tableData[row].editStatus = true
            this.tableData[row].editButton = true
        },

        // 选择商城
        selectBottomClick(column,key,row){
            column.warehouse_id = key.id
            this.tableData[row].name = key.name
            index_edit(column).then(res=>{})
        },


        // 保存按钮
        editClick(row, column, cell, event){
            this.tableData[row].editStatus = false
            this.tableData[row].editButton = false
            index_edit(column).then(res=>{})
        },

       

        /**
         * 点击页码触发
         */
        handleSizeChange(val) {
            this.limit.page_size = val
            this.loading = true
            this.getlist()
        },
        handleCurrentChange(val) {
            this.limit.page = val
            this.loading = true
            this.getlist()
        }

    }
}
</script>

<style scoped>
.entrepot-title-all{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.entrepot-title{
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    line-height: 90px;

}
.entrepot-color{
    color: #428CFF;
}
.addButton-class{
    background: #2290FF;
    color: #fff;
    border:none

}
.dropdown-all{
    height: 600px;
    overflow: auto;
}


.project-dropdown::-webkit-scrollbar
{
    width: 5px;
    height: 5px;
    background-color: #F5F5F5;
}
.project-dropdown::-webkit-scrollbar-track
{
    border-radius: 10px;
    background-color: #F5F5F5;
}
</style>