<template>
    <div>
        <div class="entrepot-title-all">
            <div class="entrepot-title">
                <div @click="warehouseClick(2)" :class="[pageType == 2?'entrepot-color':'']">库存查询</div>
                <div @click="warehouseClick(1)" :class="[pageType == 1?'entrepot-color':'']">仓库管理</div>
            </div>
            <el-button v-show="pageType == 1" class="addButton-class" @click="dialogFormVisible = true " icon="el-icon-plus">新增仓库</el-button>
        </div>
        <div v-show="pageType == 2" class="all-title-all">
            <div class="all-title-right">
                <el-form :inline="true" :model="warehouse" >
                    <el-form-item label="仓库选择">
                        <el-select  filterable v-model="warehouse.warehouse_id" @change="selectClick" placeholder="查询选项">
                        <el-option  v-for="(item,key) in sales_mao_list" :key="key"  :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-date-picker
                    v-model="Seletime"
                    type="daterange"
                    align="right"
                    unlink-panels
                    value-format="yyyy-MM-dd"
                    @blur="noblurClick"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form>
				<el-input type="text" placeholder="请输入型号" style="width: 200px;margin: 0 10px;" v-model="warehouse.goods_type"></el-input>
                <el-button class="select-button" @click="queryButtonClick">查询</el-button>
            </div>
            <div class="all-title-sales">
                <el-button class="addButton-class" @click="startButtonClick" >对账封面</el-button>
				<el-button @click="handleDownload" class="addButton-class">批量导出</el-button>
            </div>
        </div>
        

        <div v-show="pageType == 1">
            <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            element-loading-background="#fff"
            :data="tableData"
            border
            stripe
            style="width: 100%">
                <el-table-column
                    label="所属区域">
                    <template slot-scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.district" ></el-input>
                            <span v-else>{{scope.row.district}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="所属部门">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                                {{scope.row.department}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="selectBottomClick(scope.row,item,scope.$index)" v-for="(item,index) in options" :key="index">{{item.label}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column
                    label="商城名称">
                    <template slot-scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.name" ></el-input>
                            <span v-else>{{scope.row.name}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="仓库地址">
                    <template slot-scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.site" ></el-input>
                            <span v-else>{{scope.row.site}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="负责人">
                    <template slot-scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.principal" ></el-input>
                            <span v-else>{{scope.row.principal}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="座机">
                    <template slot-scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.plane" ></el-input>
                            <span v-else>{{scope.row.plane}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="电话">
                    <template slot-scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.phone" ></el-input>
                            <span v-else>{{scope.row.phone}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="QQ">
                    <template slot-scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.qq" ></el-input>
                            <span v-else>{{scope.row.qq}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="价格套餐">
                    <template slot-scope="scope">
                        <el-dropdown trigger="click">
                            <span class="el-dropdown-link">
                                {{scope.row.set_meal_info.name}}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="selectComboClick(scope.row,item,scope.$index)" v-for="(item,index) in comboList" :key="index">{{item.label}}</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
                <el-table-column
                    label="备注">
                    <template slot-scope="scope">
                        <div @dblclick="cellOneClick(scope.$index,scope.row)">
                            <el-input 
                                v-if="scope.row.editStatus"
                                size="small" 
                                v-model="scope.row.remark" ></el-input>
                            <span v-else class="beizhu">{{scope.row.remark}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template slot-scope="scope">
                        <el-button @click="delClick(scope)" type="text" size="small">删除</el-button>
                        <el-button v-if="scope.row.editButton" @click="editClick(scope.$index,scope.row)" type="text" size="small">保存</el-button>
                    </template>
                </el-table-column>
            </el-table>
				
				<el-pagination
				class="table-limit"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes= pageArray
				:page-size= this.limit.page_size
				layout="total, sizes, prev, pager, next, jumper"
				:total="inventorytotal">
				</el-pagination>
				
            <el-dialog title="添加仓库" :visible.sync="dialogFormVisible" :destroy-on-close="true" width="40%" >
                <el-form :model="formInline" class="demo-form-inline">
                    <el-row :gutter="20">
                        <el-col :span="11">
                            <el-form-item label="所属部门：" label-width="100px">
                                <el-select class="input-all" v-model="formInline.department_id" placeholder="请选择">
                                    <el-option
                                    v-for="item in options"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item label="负责人：" label-width="100px">
                                <el-input class="input-all"  v-model="formInline.principal" placeholder="负责人"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="11">
                            <el-form-item label="所属区域：" label-width="100px">
                                <el-cascader 
                                class="input-all" 
                                @change="handleChange"
                                ref="cascaderCity"
                                :props="props"></el-cascader>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item label="座机：" label-width="100px">
                                <el-input class="input-all"  v-model="formInline.plane" placeholder="座机"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="11">
                            <el-form-item label="商城名称：" label-width="100px">
                                <el-input class="input-all"  v-model="formInline.name" placeholder="商场名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item label="电话：" label-width="100px">
                                <el-input class="input-all"  v-model="formInline.phone" placeholder="电话"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="11">
                            <el-form-item label="价格套餐：" label-width="100px">
                                <el-select class="input-all" v-model="formInline.set_meal" placeholder="请选择">
                                    <el-option
                                    v-for="item in comboList"
                                    :key="item.id"
                                    :label="item.label"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item label="QQ：" label-width="100px">
                                <el-input class="input-all"  v-model="formInline.qq" placeholder="QQ"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="仓库地址：">
                        <el-input type="textarea"  v-model="formInline.site" placeholder="请输入仓库地址"></el-input>
                    </el-form-item>
                    <el-form-item label="备注：">
                        <el-input type="textarea"  v-model="formInline.remark" placeholder="请输入备注"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button  :loading="ButtonLoading" type="primary" @click="confirmButtonClick">确 定</el-button>
                </div>
            </el-dialog>

        </div>

        <div v-show="pageType == 2">
            <el-table
                v-loading="loading"
                element-loading-text="拼命加载中"
                element-loading-background="#fff"
                :data="tableDatas"
                border
                stripe
                style="width: 100%">
                <el-table-column
                label="商品图片"
                width="120">
                <template scope="scope">
                    <el-image
                    style="width: 100px; height: 100px"
                    :src="scope.row.goods_url"></el-image>
                </template>
                </el-table-column>
                <el-table-column
                width="100"
                label="条形码">
                    <template slot-scope="scope">
                        {{scope.row.bar_code}}
                    </template>
                </el-table-column>
                <!-- <el-table-column
                width="110"
                label="二维码">
                <template slot-scope="scope">
                    <vue-qr v-if="scope.row.qr_code" :margin="10" :text="scope.row.qr_code" :size="100"></vue-qr>
                    <div v-else>暂无数据</div>
                </template>
                </el-table-column> -->
                <el-table-column
                align="center"
                label="型号">
                <template slot-scope="scope">
                    {{scope.row.goods_type}}
                </template>
                </el-table-column>
                <el-table-column
                label="品牌">
                <template slot-scope="scope">
                    {{scope.row.goods_brand}}
                </template>
                </el-table-column>
                <el-table-column
                label="规格">
                <template slot-scope="scope">
                    {{scope.row.goods_norms}}
                </template>
                </el-table-column>
                <el-table-column
                label="颜色">
                <template slot-scope="scope">
                    {{scope.row.goods_color}}
                </template>
                </el-table-column>
                <el-table-column
                label="单价">
                <template slot-scope="scope">
                    {{scope.row.price}}
                </template>
                </el-table-column>

                <el-table-column label="期初库存">
                    <el-table-column
                        label="数量">
                        <template slot-scope="scope">
                            {{scope.row.cq.count?scope.row.cq.count:''}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="金额">
                        <template slot-scope="scope">
                            {{parseFloat(scope.row.cq.total)?scope.row.cq.total:''}}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="本期入库">
                    <el-table-column
                        label="数量">
                        <template slot-scope="scope">
                            {{scope.row.bqrk.count?scope.row.bqrk.count:''}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="金额">
                        <template slot-scope="scope">
                            {{parseFloat(scope.row.bqrk.total)?scope.row.bqrk.total:''}}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="本期调货">
                    <el-table-column
                        label="数量">
                        <template slot-scope="scope">
                            {{scope.row.bqdh.count?scope.row.bqdh.count:''}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="金额">
                        <template slot-scope="scope">
                            {{parseFloat(scope.row.bqdh.total)?scope.row.bqdh.total:''}}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="本期退货">
                    <el-table-column
                        prop="bqck.count"
                        label="数量">
                        <template slot-scope="scope">
                            {{scope.row.bqck.count?scope.row.bqck.count:''}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="金额">
                        <template slot-scope="scope">
                            {{parseFloat(scope.row.bqck.total)?scope.row.bqck.total:''}}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="本期销售">
                    <el-table-column
                        label="数量">
                        <template slot-scope="scope">
                            {{scope.row.bqxs.count?scope.row.bqxs.count:''}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="bqxs.total"
                        label="金额">
                        <template slot-scope="scope">
                            {{parseFloat(scope.row.bqxs.total)?scope.row.bqxs.total:''}}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column label="本期库存">
                    <el-table-column
                        prop="bqkc.count"
                        label="数量">
                        <template slot-scope="scope">
                            {{scope.row.bqkc.count?scope.row.bqkc.count:''}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="bqkc.total"
                        label="金额">
                        <template slot-scope="scope">
                            {{parseFloat(scope.row.bqkc.total)?scope.row.bqkc.total:''}}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                label="操作">
                    <template>
                        <el-button type="text">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
				<el-pagination
								class="table-limit"
								@size-change="handleSizeChange22"
								@current-change="handleCurrentChange22"
								:current-page="currentPage"
								:page-sizes= pageArray
								:page-size= this.limit.page_size
								layout="total, sizes, prev, pager, next, jumper"
								:total="warehousetotal">
								</el-pagination>
        </div>
    </div>
</template>

<script>
import { get_list , department_list,save,update,warehouse_goods , deletes} from '@/api/entrepot.js'
import { sales_list } from '@/api/sales.js'
import { get_area_list } from '@/api/index.js'
import { combo_list } from '@/api/combo.js'
export default {
    name:'Entrepot',
    data(){
        return{
            limit:{
                page:1,
                page_size:10, 
            },
            pageArray:this.$store.state.pageArray,
            pageType:2,
            loading:true,
            tableData:[],
            inventorytotal:0,
            warehousetotal:0,
            currentPage:1,
            dialogFormVisible:false,
            formInline: {
                province_id: 0,
                city_id: 0,
                district_id:0,
                department_id:'',
                name:'',
                site:'',
                principal:'',
                plane:'',
                phone:'',
                qq:'',
                set_meal:'',
                remark:''
            },
            options:[],
            comboList:[],
            ButtonLoading:false,
            props: {
                lazy: true,
                lazyLoad (node, resolve) {
                    let pid = node.data == undefined ? 0 : node.data.id
                    get_area_list({pid:pid}).then(res=>{
                        let data = res.data
                        const nodes = data.map(item => ({
                            id:item.id,
                            value:item.id,
                            leaf: item.level > 1,
                            level: item.level,
                            label: item.name
                        }));
                        resolve(nodes);
                    })
                }
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            warehouse:{
                page:1,
                page_size:10,
                start:'',
                end:'',
                warehouse_id:'',
				goods_type:''
            },
            totals:0,
            sales_mao_list:[],
            tableDatas:[],
        }
    },
    created(){

        // 获取首页数据
        this.getlist()
        // 获取部门列表
        this.get_department_list()
        // 获取套餐列表
        this.get_combo_list()
        // 获取默认时间
        this.getMtime()
        // 获取仓库列表
        this.get_changkuList()
        
        // setTimeout(() => {
            
        // }, 1000);
    },
    methods:{
		//删除仓库
		delClick(scope){
			let data = {
			    warehouse_id:scope.row.id,
			}
			deletes(data).then(res=>{
				this.tableData.splice(scope.$index,1)
			    this.$message({
			        type: 'success',
			        message: '删除成功'
			    })
			})
		},
		// 导出数据
		handleDownload() {
		    import('@/vendor/Export2Excel').then(excel => {
		      const multiHeader =
		      [
		        ['','','','','','','期初库存','', '本期入库', '', '本期调货', '', '本期退货','', '本期销售', '', '本期库存', ''],
		      ] // 前两行的表头数据，二维数组，不够的用空白补全
		      const header = ['条形码', '型号', '品牌', '规格', '颜色', '单价', '数量', '金额', '数量', '金额','数量','金额','数量','金额','数量','金额','数量','金额'] // 最后一行的表头数据
		      const filterVal = ['bar_code', 'goods_type', 'goods_brand', 'goods_norms', 'goods_color', 'price','cq_count','cq_total','bqrk_count','bqrk_total','bqdh_count','bqdh_total','bqck_count','bqck_total','bqxs_count','bqxs_total','bqkc_count','bqkc_total']
		      const list = this.tableDatas
		      const data = this.formatJson(filterVal, list)
		      // console.log(data)
		      // return
		      // const merges = ['A1:K1','A3:A4','B3:C3','D3:E3','F3:G3','H3:I3','J3:K3'] // 合并单元格的数据，如何合并参考上面图片的蓝色背景部分
		      excel.export_json_to_excel({
		        multiHeader,
		        header,
		        // merges,
		        data,
		        filename:'库存产品清单',
		      })
		    })
		},
		formatJson(filterVal, jsonData) {
		  return jsonData.map(v => filterVal.map(j => {
		    return v[j]
		  }))
		},
        // 仓库管理列表
        getlist(){
            get_list(this.limit).then(res=>{
                let data = res.data.data
                data.forEach(element => {
                    element.editStatus = false
                    element.editButton = false
                });
                this.tableData = data
                this.inventorytotal = res.data.total
                this.loading = false
            })
        },

        // 对账封面
        startButtonClick(){
            let str = '';
            this.sales_mao_list.forEach(element=>{
                if(element.id == this.warehouse.warehouse_id){
                    str = element.name
                }
            })
            let data = {
                start:this.warehouse.start,
                end:this.warehouse.end,
                warehouse_id:this.warehouse.warehouse_id,
                name:str
            }
            let srtData = JSON.stringify(data)
            this.$router.push('/inventory?key=' + srtData)
        },
        //库存查询
		  //.............................
        getGoodsList(){
            warehouse_goods(this.warehouse).then(res=>{
				let data = res.data.data
				data.forEach(element => {
					
				   element.cq_count=element.cq.count
				   element.cq_total=element.cq.total
				   
				   element.bqrk_count=element.bqrk.count
				   element.bqrk_total=element.bqrk.total
				   
				   element.bqdh_count=element.bqdh.countt
				   element.bqdh_total=element.bqdh.total
				   
				   element.bqck_count=element.bqck.count
				   element.bqck_total=element.bqck.total
				
					element.bqxs_count=element.bqxs.count
					element.bqxs_total=element.bqxs.total
					
					element.bqkc_count=element.bqkc.count
					element.bqkc_total=element.bqkc.total
				});
                this.tableDatas = data
                this.loading = false
				this.warehousetotal = res.data.total
            })
        },

        // 获取仓库列表
        get_changkuList(){
            sales_list({all:true}).then(res=>{
                this.sales_mao_list = res.data
                this.warehouse.warehouse_id = res.data[0].id
                this.getGoodsList()
            })
        },

        // 下拉选择
        selectClick(e){
            this.warehouse.warehouse_id = e
        },
        

        // 确定新增按钮
        confirmButtonClick(){
            this.ButtonLoading = true
            save(this.formInline).then(res=>{
                this.dialogFormVisible = false
                this.ButtonLoading = false
                if(res.code == 200){
                    this.getlist()
                    this.$message({
                        type: 'success',
                        message: res.msg
                    });
                }
            })  
        },
        //失去焦点
        noblurClick(e){
            this.warehouse.start = this.Seletime[0]
            this.warehouse.end = this.Seletime[1]
            this.$forceUpdate();
        },

        // 点击查询
        queryButtonClick(){
            let timeMerge = this.Seletime[0] + '~' + this.Seletime[1]
            this.limit.create_time = timeMerge
            this.loading = true
            this.warehouse.page = 1
            this.getGoodsList()
        },

        // 下拉选择
        selectClick(e){
            this.limit.warehouse_id = e
        },

        //仓库  库存切换
        warehouseClick(key){
            if(this.pageType == key){
                return
            }
            this.loading = true
            if(key == 1){
                this.getlist()
            }else{
                this.getGoodsList()
            }
            this.pageType = key
        },

        // 选择部门
        selectBottomClick(column,key,row){
            let data = {
                warehouse_id:column.id,
                department_id:key.id
            }
            this.tableData[row].department = key.label
            update(data).then(res=>{})
        },

        // 选择套餐
        selectComboClick(column,key,row){
            let data = {
                warehouse_id:column.id,
                set_meal:key.id
            }
            this.tableData[row].set_meal_info.name = key.label
            this.tableData[row].set_meal_info.id = key.id
            update(data).then(res=>{})
        },

        // 单元格双击
        cellOneClick(row, column, cell, event){
            this.tableData[row].editStatus = true
            this.tableData[row].editButton = true
        },

        // 保存按钮
        editClick(row, column, cell, event){
            let data = {
                warehouse_id:column.id,
                name: column.name,
                site: column.site,
                principal: column.principal,
                plane: column.plane,
                phone: column.phone,
                qq: column.qq,
                remark: column.remark
            }
            this.tableData[row].editStatus = false
            this.tableData[row].editButton = false
            update(data).then(res=>{})
        },

        // 获取部门列表
        get_department_list(){
            department_list().then(res=>{
                this.options = res.data.map(item => ({
                    id:item.id,
                    label: item.name
                }));
            })
        },

        //解决时间不刷新问题
        changeVal(){
            this.$forceUpdate();
        },


        // 获取套餐列表
        get_combo_list(){
            combo_list().then(res=>{
                this.comboList = res.data.map(item => ({
                    id:item.id,
                    label: item.name
                }));
            })
        },

        // 级联选中触发
        handleChange(item){
            this.formInline.province_id = item[0]
            this.formInline.city_id = item[1]
            // this.formInline.district_id = item[2]
        },

        /**
         * 仓库管理点击页码触发
         */
        handleSizeChange(val) {
            this.limit.page_size = val
            this.loading = true
            this.getlist()
        },
        handleCurrentChange(val) {
            this.limit.page = val
            this.loading = true
            this.getlist()
        },
		  /**
		   * 库存查询点击页码触发
		   */
		  handleSizeChange22(val) {
		      this.warehouse.page_size = val
		      this.loading = true
		      this.getGoodsList()
		  },
		  handleCurrentChange22(val) {
		      this.warehouse.page = val
		      this.loading = true
		      this.getGoodsList()
		  },
        // 时间选择获取最近一个月
        getMtime(){
            let end = new Date();
            let nowend = this.formatDate(end);
            let start = new Date();
            start.setDate(1);
            start.setHours(0);
            start.setSeconds(0);
            start.setMinutes(0);
            start = start.getTime()
            let nowstart = this.formatDate(start);
            this.warehouse.start = nowstart
            this.warehouse.end = nowend
            this.Seletime = [nowstart,nowend]
        },

        // 格式化日期
        formatDate(date) {
            var date = new Date(date);
            var YY = date.getFullYear() + '-';
            var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
            return YY + MM + DD ;
        },

    }
}
</script>

<style scoped>
.beizhu{
    color: #FFAF24;

}
.entrepot-title-all{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.entrepot-title{
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    line-height: 90px;

}
.entrepot-color{
    color: #428CFF;
}
.addButton-class{
    background: linear-gradient(90deg, #FF861B, #FFB527);
    box-shadow: 0px 5px 30px 0px rgba(255, 142, 66, 0.4);
    border-radius: 6px;
    border: none;
    color: #fff;
}
.select-button{
    background: linear-gradient(90deg, #FF774E, #FF4C16);
    box-shadow: 0px 5px 30px 0px rgba(255, 77, 23, 0.4);
    border-radius: 6px;
    color: #fff;
    border: none;
    margin-left: 40px;
}
.input-all{
    width: 90%;
}
.all-title-right{
    display: flex;
    align-items: center;
}
.el-form-item{
    margin-bottom: 0px !important;
}
.all-title-all{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
</style>