import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store'
import 'babel-polyfill';

Vue.use(ElementUI);

//阿里可伸缩布局
import 'lib-flexible'

// 全局生成二维码
import vueQr from 'vue-qr'
Vue.component('vueQr',vueQr)

import Print from './plugins/print/print'
Vue.use(Print)

// import Print from 'vue-print-nb'
// Vue.use(Print); //注册

// 引入全局过滤器
import * as custom  from './filters/index.js'
Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
