import service from '@/utils/request.js'

// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/**************************************
 * 采购管理
 **************************************/
/**
 * 采购管理添加上传图片
 */
export function index_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/upload',
        method: 'post',
        data
    })
}

/**
 * 采购管理添加
 */
export function index_add(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/add',
        method: 'post',
        data
    })
}

/**
 * 采购管理删除
 */
export function index_del(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/del',
        method: 'post',
        data
    })
}


/**
 * 采购管理查询
 */
export function index_index(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/index',
        method: 'get',
        params:data
    })
}


/**
 * 根据获取商品数据
 */
export function index_showgoodsdetail(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/showgoodsdetail',
        method: 'post',
        data
    })
}


/**
 * 提交用户上传或者扫码的数据
 */
export function scancode(data={}) {
    // data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/scancode',
        method: 'post',
        data
    })
}


/**
 * 提交用户上传或者扫码的数据
 */
export function indexcount(data={}) {
    // data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/indexcount',
        method: 'post',
        data
    })
}


/**
 * 添加套账
 */
export function editgoods(data={}) {
    // data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/editgoods',
        method: 'post',
        data
    })
}


/**
 * 
 * @param {统计查询-修改奖励} data 
 */
export function editreward(data={}) {
    // data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/editreward',
        method: 'post',
        data
    })
}



/**
 * 
 * @param {修改采购管理} data 
 */
export function editremark(data={}) {
    // data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/editremark',
        method: 'post',
        data
    })
}

/**
 * 
 * @param {修改商品详情图片} data 
 */
export function editgoods_detail(data={}) {
    // data.sign = encrypt(data);
    return service.request({
        url: '/admin/purchase/editgoods_detail',
        method: 'post',
        data
    })
}