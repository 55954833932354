<template>
  <div>
    <div class="statistics-all">
      <div class="title">账号管理</div>
      <el-button @click="dialogFormVisible = true">添加账号</el-button>
    </div>
    
    <!-- 表格 -->
    <el-table :data="tableData" stripe style="width: 100%" element-loading-text="拼命加载中" element-loading-background="#fff"  border>
      <el-table-column prop="id" label="ID" width="180" align="center" ></el-table-column>
      <el-table-column label="账号" width="180" align="center">
        <template scope="scope">
          <span>{{scope.row.admin_name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="姓名" align="center">
        <template scope="scope">
            <div @dblclick="cellOneClick(scope.$index,scope.row)">
                <el-input 
                    v-if="scope.row.editStatus"
                    size="small" 
                    v-model="scope.row.real_name" ></el-input>
                <span v-else>{{scope.row.real_name}}</span>
            </div>
        </template>
      </el-table-column>
      <el-table-column label="昵称" align="center" >
        <template scope="scope">
            <div @dblclick="cellOneClick(scope.$index,scope.row)">
                <el-input 
                    v-if="scope.row.editStatus"
                    size="small" 
                    v-model="scope.row.nickname" ></el-input>
                <span v-else>{{scope.row.nickname}}</span>
            </div>
        </template>
      </el-table-column>
      <el-table-column label="头像" align="center">
         <template scope="scope">
             <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.avatar | replaceUrl "
              ></el-image>
          </template>
      </el-table-column>
      <el-table-column label="备注信息" align="center">
        <template scope="scope">
            <div @dblclick="cellOneClick(scope.$index,scope.row)">
                <el-input 
                    v-if="scope.row.editStatus"
                    size="small" 
                    v-model="scope.row.remark" ></el-input>
                <span v-else>{{scope.row.remark}}</span>
            </div>
        </template>
      </el-table-column>
      <el-table-column label="角色" align="center">
        <template scope="scope">
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    {{scope.row.role_name}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item 
                        @click.native="selectBottomClick(scope.row,item,scope.$index)" 
                        v-for="(item,index) in UserList" 
                        :key="index">{{item.title}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column label="品牌" align="center">
        <template scope="scope">
            <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                    {{scope.row.brand}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item 
                        @click.native="selectBrandClick(scope.row,item,scope.$index)" 
                        v-for="(item,index) in brandList" 
                        :key="index">{{item.name}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </template>
      </el-table-column>
      <el-table-column label="地址" align="center">
        <template scope="scope">
          <div @dblclick="cellOneClick(scope.$index,scope.row)">
            <el-cascader 
            class="input-all" 
            v-if="scope.row.editStatus"
            @change="handleChanges"
            :props="props"></el-cascader>
            <span v-else>{{scope.row.area}}</span>
          </div>

          

        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
            <el-button @click="delClick(scope.row)" type="text" size="small">删除</el-button>
            <el-button @click="editpwdClick(scope.row)" type="text" size="small">修改密码</el-button>
            <el-upload
            action="asd"
            :multiple="false"
            name="image"
            :show-file-list="false"
            :limit="100"
           :http-request="uploadsClick"
           ><el-button @click="updataimage(scope.row)" type="text" size="small">修改头像</el-button></el-upload>
            <el-button v-if="scope.row.editButton" @click="editClick(scope.$index,scope.row)" type="text" size="small">保存</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      class="table-limit"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageArray"
      :page-size="this.limit.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>


    <el-dialog title="添加账号" :visible.sync="dialogFormVisible" :destroy-on-close="true" width="40%" >
        <el-form :model="formInline" class="demo-form-inline">
            <el-row :gutter="20">
                <el-col :span="11">
                    <el-form-item label="账号：" label-width="100px">
                        <el-input class="input-all"  v-model="formInline.admin_name" placeholder="账号"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="密码：" label-width="100px">
                        <el-input class="input-all"  v-model="formInline.password" placeholder="密码"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="11">
                    <el-form-item label="权限：" label-width="100px">
                        <el-select class="input-all" v-model="formInline.role_id" placeholder="请选择">
                            <el-option
                            v-for="item in UserList"
                            :key="item.id"
                            :label="item.title"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="所属区域：" label-width="100px">
                        <el-cascader 
                        class="input-all" 
                        @change="handleChange"
                        ref="cascaderCity"
                        :props="props"></el-cascader>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="11">
                    <el-form-item label="昵称：" label-width="100px">
                        <el-input class="input-all"  v-model="formInline.nickname" placeholder="昵称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="用户头像：" label-width="100px">
                        <el-upload
                        action="asd"
                        :http-request="uploadClick"
                        :multiple="false"
                        name="image"
                        :limit="1">
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="11">
                    <el-form-item label="品牌：" label-width="100px">
                        <el-select class="input-all" v-model="formInline.brand_id" placeholder="请选择">
                            <el-option
                            v-for="item in brandList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="11">
                    <el-form-item label="姓名：" label-width="100px">
                        <el-input class="input-all"  v-model="formInline.real_name" placeholder="姓名"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="备注：">
                <el-input type="textarea"  v-model="formInline.remark" placeholder="备注"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button  :loading="ButtonLoading" type="primary" @click="confirmButtonClick">确 定</el-button>
        </div>
    </el-dialog>


    <el-dialog title="修改密码" :visible.sync="dialogPwd" :destroy-on-close="true" @close="closePwd" width="40%">
      <el-form :model="password" class="demo-form-inline">
            <el-row :gutter="20">
                <el-col :span="11">
                    <el-form-item label="新密码：" label-width="100px">
                        <el-input class="input-all"  v-model="password.password" placeholder="请输入新密码"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogPwd = false">取 消</el-button>
            <el-button  :loading="ButtonLoading" type="primary" @click="pwdClick">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { account_list,account_save,account_delete,account_update,account_role,account_brand } from "@/api/account.js";
import { get_area_list,upload_image } from '@/api/index.js'
export default {
  name: "Entrepot",
  data() {
    return {
      pageArray:this.$store.state.pageArray,
      limit: {
        page: 1,
        page_size: 10,
      },
      total: 0,
      loading: true,
      currentPage: 1,
      tableData: [],
      UserList:[],
      brandList:[],
      dialogFormVisible:false,
      formInline: {
        admin_name: '',
        password: '',
        role_id:'',
        nickname:'',
        avatar:'',
        brand_id:'',
        remark:'',
        province_id:'',
        city_id:'',
        district_id:'',
        real_name:'',
      },
      addressID:{
        province_id:'',
        city_id:'',
        district_id:''
      },
      dialogPwd:false,
      password:{
        account_id:'',
        password:'',
      },
      ButtonLoading:false,
      props: {
          lazy: true,
          lazyLoad (node, resolve) {
              let pid = node.data == undefined ? 0 : node.data.id
              get_area_list({pid:pid}).then(res=>{
                let data = res.data
                const nodes = data.map(item => ({
                    id:item.id,
                    value:item.id,
                    leaf: item.level > 2,
                    level: item.level,
                    label: item.name
                }));
                resolve(nodes);
              })
          }
      },
      selectRow:{}
    };
  },
  created() {
    // 获取首页数据
    this.getinfo();

    this.getRoleList()

    this.getbrandList()
  },
  methods: {
    //获取列表信息
    getinfo() {
      account_list(this.limit).then((res) => {
        let data = res.data.data;
        data.forEach(element => {
            element.editStatus = false
            element.editButton = false
        });
        this.tableData = data;
        this.total = res.data.total;
        this.loading = false;
      });
    },

    // 修改密码
    pwdClick(){
      if(!this.password.password){
        this.$message.error('请输入密码');
        return
      }
      account_update(this.password).then(res=>{
        this.dialogPwd = false
        this.password.password = ''
      })
    },
    // 关闭时候的回调
    closePwd(){
      this.password.password = ''
    },

    // 级联选中触发
    handleChange(item){
        console.log(item)
        this.formInline.province_id = item[0]
        this.formInline.city_id = item[1]
        this.formInline.district_id = item[2]
    },

    // 列表选中触发
    handleChanges(item){
        this.addressID.province_id = item[0]
        this.addressID.city_id = item[1]
        this.addressID.district_id = item[2]
    },

    // 修改密码
    editpwdClick(row){
      this.dialogPwd = true
      this.password.account_id = row.id
    },

    //获取角色信息
    getRoleList(){
        account_role().then(res=>{
            this.UserList = res.data
        })
    },

    // 获取品牌列表
    getbrandList(){
        account_brand().then(res=>{
          let data = [{
            id:' ',
            name:'空'
          }]
          this.brandList = data.concat(res.data)
        })
    },
    // 单元格双击
    cellOneClick(row, column, cell, event){
        this.tableData[row].editStatus = true
        this.tableData[row].editButton = true
    },

    // 保存按钮
    editClick(row, column, cell, event){
        let data = {
            nickname:column.nickname,
            remark: column.remark,
            real_name: column.real_name,
            account_id:column.id
        }
        if(this.addressID.district_id){
          data.province_id = this.addressID.province_id
          data.city_id = this.addressID.city_id
          data.district_id = this.addressID.district_id
        }
        this.tableData[row].editStatus = false
        this.tableData[row].editButton = false
        account_update(data).then(res=>{
          this.addressID.province_id = ''
          this.addressID.city_id = ''
          this.addressID.district_id = ''
        })
    },

    // 确定新增按钮
    confirmButtonClick(){
        this.ButtonLoading = true
        account_save(this.formInline).then(res=>{
            this.dialogFormVisible = false
            this.ButtonLoading = false
            if(res.code == 200){
                this.getinfo()
                this.$message({
                    type: 'success',
                    message: res.msg
                });
            }
        })  
    },

    // 上传头像
    uploadClick(file){
      upload_image({image:file.file}).then(res=>{
          this.formInline.avatar = res.data
      })
    },

    // 用户修改上传头像
    uploadsClick(file){
      upload_image({image:file.file}).then(res=>{
        let data = {
            account_id:this.selectRow.id,
            avatar:res.data,
        }
        account_update(data).then(res=>{
          this.$message({
            type:'success',
            message:'修改成功'
          })
          this.getinfo()
        })
      })
    },
    //保存当前选中的标识
    updataimage(row){
      this.selectRow = row
    },

    // 删除
    delClick(row){
        this.$confirm('此操作将永久删除 , 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                account_delete({account_id:row.id}).then(res=>{
                    if(res.code == 200){
                        // 确定删除
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.selectCLick();
                    }
                })
                
            }).catch(() => {         
        });
    },
    // 选择角色
    selectBottomClick(column,key,row){
        this.role_id = key.id
        this.tableData[row].role_name = key.title
        account_update({role_id:key.id,account_id:column.id}).then(res=>{})
    },
    // 选择品牌
    selectBrandClick(column,key,row){
        this.brand_id = key.id
        this.tableData[row].brand = key.name
        account_update({brand_id:key.id,account_id:column.id}).then(res=>{})
    },
    //分页
    handleSizeChange(val) {
      this.limit.page_size = val;
      this.loading = true;
      this.getinfo();
    },
    handleCurrentChange(val) {
      this.limit.page = val;
      this.loading = true;
      this.getinfo();
    },
  },
};
</script>

<style scoped>
.title {
  width: 120px;
  height: 40px;
  padding-left: 10px;
  font-size: 28px;
  font-weight: 500;
  color: #333333;
  border-left: #418bff solid 8px;
  margin-bottom: 20px;
}
.statistics-all{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom:40px
}
</style>