import service from '@/utils/request.js'

// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/**************************************
 * 销售员管理模块
 **************************************/
/**
 * 获取统计列表
 */
export function saleuser(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/saleuser/index',
        method: 'get',
        params:data
    })
}
