import service from '@/utils/request.js'

// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/**************************************
 * 销售退货管理
 **************************************/
/**
 * 销售退货管理
 */
export function withdraw_list(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/saleback/index',
        method: 'get',
        params:data
    })
}

