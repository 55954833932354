<template>
    <div>

        <!-- <router-link
            v-for="item in menuList"
            :key="item.name"
            :to="item.path"
            >
            {{item.title}}
        </router-link> -->
        <div class="header-avatar">
            <el-avatar :size="50" class="avatar-img" :src="userInfo.avatar"></el-avatar>
            <div>{{userInfo.nickname}}</div>
        </div>

        <el-row class="tac">
            <el-col :span="24">
                <el-menu
                :default-active="onRoutes"
                class="el-menu-vertical-demo"
                background-color="#F0F2F3"
                text-color="#333333"
                active-text-color="#418CFF"
                @open="handleOpen"
                @close="handleClose"
                router>
                    <el-menu-item v-for="(item,key) in menuList" :key="key" :index="item.path">
                        <i class="el-icon-menu"></i>
                        <span slot="title">{{item.title}}</span>
                    </el-menu-item>
                </el-menu>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    name:'Nav',
    data(){
        return{
            activeNav: '0',
            userInfo:{}
        }
    },
    computed: {
        onRoutes(){
            let path = this.$route.path;
            return path
        },
        menuList() {
            return this.$store.state.routes || [];
        },
        keyData(){
            return function (key){
                return key + 1
            }
        }
    },
    created(){
        // 刷新后 从缓存中重新获取路由数据
        const menu = JSON.parse(sessionStorage.getItem('menu'));
        if(menu) this.$store.commit('SET_ROUTES', menu);
        // 刷新后 从缓存中重新获取用户数据
        const userinfo = JSON.parse(sessionStorage.getItem('user_info'));
        if(userinfo) this.$store.commit('SET_USERINFO', userinfo);
        this.userInfo = userinfo
    },
    methods:{
        handleOpen(key, keyPath) {
        },
        handleClose(key, keyPath) {
        }
    }
}
</script>
<style scoped>
.header-avatar{
    padding: 40px  0 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: #3F88FF;
    font-weight: 800;
}
.avatar-img{
   margin-right: 20px;
}

</style>