import service from '@/utils/request.js'
// 导入签名计算
import { encrypt } from '@/utils/encrypt'
/**
 * 套账列表
 */
export function list(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/set_meal/list',
        method: 'get',
        params:data
    })
}

/**
 * 添加套账
 */
export function save(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/set_meal/save',
        method: 'post',
        data
    })
}

/**
 * 删除套账
 */
export function deletes(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/set_meal/delete',
      method: 'post',
      data
    })
  }

/**
 * 编辑套账
 */
export function update(data={}) {
    data.sign = encrypt(data);
    return service.request({
      url: '/admin/set_meal/update',
      method: 'post',
      data
    })
  }