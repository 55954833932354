<template>
    <div id="main-warp">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
    name:'Main',
    methods:{
        
    }
}
</script>
<style scoped>
#main-warp{
    background: #ffffff;
    padding: 25px;
}
</style>