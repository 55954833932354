<template>
    <div>
        <div class="entrepot-title-all">
            <div class="entrepot-title">商品管理</div>
        </div>
        <el-row :gutter="20">
            <el-col :span="22">
                <el-form :inline="true" :model="limit" >
                    <el-form-item label="查询选项">
                        <el-select clearable v-model="limit.type" placeholder="查询选项">
                        <el-option v-for="(item,key) in xinghao" :key="key"  :label="item.txt" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="查询内容" class="left-all">
                        <el-input v-model="limit.pm" placeholder="查询内容"></el-input>
                    </el-form-item>
                    <el-form-item label="单价："  >
                        <el-input class="selecr-time" v-model="limit.min" placeholder="最小单价"></el-input>
                    </el-form-item>
                    <el-form-item label="至：">
                        <el-input class="selecr-time" v-model="limit.max" placeholder="最大单价"></el-input>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="2">
                <el-button @click="selectCLick" class="sele-button">查询</el-button>
            </el-col>
        </el-row>


        <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-background="#fff"
        :data="tableData"
        border
        stripe
        style="width: 100%">
            <el-table-column
                width="120"
                label="商品">
                <template scope="scope">
                    <el-image
                    style="width: 100px; height: 100px"
                    :src="scope.row.goods_url | replaceUrl "
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column
                width="130"
                label="条形码">
                <template scope="scope">
                    <span >{{scope.row.bar_code}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="二维码">
                <template scope="scope">
                    <div @dblclick="cellOneClick(scope.$index,scope.row)">
                        <div v-if="!scope.row.editStatus">
                            <vue-qr v-if="scope.row.qr_code" :margin="10"  :text="scope.row.qr_code" :size="80"></vue-qr>
                            <div v-else>暂无数据</div>
                        </div>
                        <el-input 
                            v-else
                            size="small" 
                            v-model="scope.row.qr_code" ></el-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="所属商城">
                <template scope="scope">
                    <span >{{scope.row.warehouse_name}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="型号">
                <template scope="scope">
                    <!-- <div @dblclick="cellOneClick(scope.$index,scope.row)"> -->
                        <!-- <el-input 
                            v-if="scope.row.editStatus"
                            size="small" 
                            v-model="scope.row.goods_type" ></el-input> -->
                        <span >{{scope.row.goods_type}}</span>
                    <!-- </div> -->
                </template>
            </el-table-column>
            <el-table-column
                label="品牌">
                <template scope="scope">
                    <!-- <div @dblclick="cellOneClick(scope.$index,scope.row)"> -->
                        <!-- <el-input 
                            v-if="scope.row.editStatus"
                            size="small" 
                            v-model="scope.row.goods_brand" ></el-input> -->
                        <span >{{scope.row.goods_brand}}</span>
                    <!-- </div> -->
                </template>
            </el-table-column>
            <el-table-column
                label="规格">
                <template scope="scope">
                    <!-- <div @dblclick="cellOneClick(scope.$index,scope.row)"> -->
                        <!-- <el-input 
                            v-if="scope.row.editStatus"
                            size="small" 
                            v-model="scope.row.goods_norms" ></el-input> -->
                        <span>{{scope.row.goods_norms}}</span>
                    <!-- </div> -->
                </template>
            </el-table-column>
            <el-table-column
                label="类别">
                <template scope="scope">
                    <!-- <div @dblclick="cellOneClick(scope.$index,scope.row)"> -->
                        <!-- <el-input 
                            v-if="scope.row.editStatus"
                            size="small" 
                            v-model="scope.row.goods_class" ></el-input> -->
                        <span >{{scope.row.goods_class}}</span>
                    <!-- </div> -->
                </template>
            </el-table-column>
            <el-table-column
                label="颜色">
                <template scope="scope">
                    <!-- <div @dblclick="cellOneClick(scope.$index,scope.row)"> -->
                        <!-- <el-input 
                            v-if="scope.row.editStatus"
                            size="small" 
                            v-model="scope.row.goods_color" ></el-input> -->
                        <span class="beizhu">{{scope.row.goods_color}}</span>
                    <!-- </div> -->
                </template>
            </el-table-column>
            <el-table-column
                label="奖励">
                <template scope="scope">
                    <!-- <div @dblclick="cellOneClick(scope.$index,scope.row)"> -->
                        <!-- <el-input 
                            v-if="scope.row.editStatus"
                            size="small" 
                            v-model="scope.row.reward" ></el-input> -->
                        <span  class="beizhu">{{scope.row.reward}}</span>
                    <!-- </div> -->
                </template>
            </el-table-column>
            <el-table-column
                label="附带配件">
                <template scope="scope">
                    <!-- <div @dblclick="cellOneClick(scope.$index,scope.row)"> -->
                        <!-- <el-input 
                            v-if="scope.row.editStatus"
                            size="small" 
                            v-model="scope.row.goods_other" ></el-input> -->
                        <span class="beizhu">{{scope.row.goods_other}}</span>
                    <!-- </div> -->
                </template>
            </el-table-column>
            <el-table-column
                label="单价">
                <template scope="scope">
                    <!-- <div @dblclick="cellOneClick(scope.$index,scope.row)"> -->
                        <!-- <el-input 
                            v-if="scope.row.editStatus"
                            size="small" 
                            v-model="scope.row.price" ></el-input> -->
                        <span  class="beizhu">{{scope.row.price}}</span>
                    <!-- </div> -->
                </template>
            </el-table-column>

            <el-table-column
                label="状态">
                <template scope="scope">
                    <el-tag v-if="scope.row.is_shipment == 0">未出货</el-tag>
                    <el-tag type="success" v-else>已出货</el-tag>
                    <el-tag v-if="scope.row.status == 0">正常商品</el-tag>
                    <el-tag v-if="scope.row.status == 1">总仓退货</el-tag>
                    <el-tag v-if="scope.row.status == 2">分仓退货</el-tag>
                    
                </template>
            </el-table-column>
            <el-table-column
                label="售卖">
                <template scope="scope">
                    <el-tag v-if="scope.row.is_sell == 0">未售卖</el-tag>
                    <el-tag type="success" v-else>已售卖</el-tag>
                </template>
            </el-table-column>

            <el-table-column
                width="80"
                label="操作">
                <template slot-scope="scope">
                    <el-button @click="delClick(scope.row)" type="text" size="small">删除</el-button>
                    <el-button v-if="scope.row.editButton" @click="editClick(scope.$index,scope.row)" type="text" size="small">保存</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
        class="table-limit"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageArray"
        :page-size= this.limit.page_size
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

    </div>
</template>

<script>
import { index_del ,index_list,editqrcode} from '@/api/shop.js'
export default {
    name:'Shop',
    data(){
        return{
            pageArray:this.$store.state.pageArray,
            limit:{
                page:1,
                page_size:10, 
                type:'',
                pm:'',
                min:'',
                max:'',
            },
            pageType:1,
            loading:true,
            tableData:[],
            total:0,
            currentPage:1,
            dialogFormVisible:false,
            formInline: {
                user: '',
                region: ''
            },
            xinghao:[{
                id:1,
                txt:'型号',
            },{
                id:2,
                txt:'品牌',
            },{
                id:3,
                txt:'条码'
            },{
                id:4,
                txt:'二维码'
            },{
                id:5,
                txt:'所属商城'
            }],
            loading:false,
            tableData:[],
        }
    },
    created(){
        // 获取首页数据
        this.getlist()
    },
    methods:{
        // 仓库管理列表
        getlist(){
            index_list(this.limit).then(res=>{
                let data = res.data.data
                if(JSON.stringify(data) != undefined){
                    data.forEach(element => {
                        element.editStatus = false
                        element.editButton = false
                    });
                    this.total = res.data.total
                }
                this.tableData = data
                this.loading = false
            })
        },
        // 查询
        selectCLick(){
            this.limit.page = 1
            this.loading = true
            this.getlist()
        },
        // 修改二维码
        editClick(row, column, cell, event){
            let data = {
                id:column.id,
                qr_code: column.qr_code
            }
            this.tableData[row].editStatus = false
            this.tableData[row].editButton = false
            editqrcode(data).then(res=>{
                this.$message({
                    type:'success',
                    message:'修改成功'
                })
            })
        },
        // 删除
        delClick(row){
            this.$confirm('此操作将永久删除 , 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    index_del({id:row.id}).then(res=>{
                        if(res.code == 200){
                            // 确定删除
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.selectCLick();
                        }
                    })
                    
                }).catch(() => {         
            });
        },
        // 单元格双击
        cellOneClick(row, column, cell, event){
            this.tableData[row].editStatus = true
            this.tableData[row].editButton = true
        },


        /**
         * 点击页码触发
         */
        handleSizeChange(val) {
            this.limit.page_size = val
            this.loading = true
            this.getlist()
        },
        handleCurrentChange(val) {
            this.limit.page = val
            this.loading = true
            this.getlist()
        }

    }
}
</script>

<style scoped>
.entrepot-title-all{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.entrepot-title{
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    line-height: 90px;

}
.entrepot-color{
    color: #428CFF;
}
.addButton-class{
    background: #2290FF;
    color: #fff;

}
.sele-button{
    background: linear-gradient(90deg, #FF774E, #FF4C16);
    box-shadow: 0px 5px 30px 0px rgba(255, 77, 23, 0.4);
    border-radius: 6px;
    border:none;
    color:#fff

}
.selecr-time{
    width:150px
}
.left-all{
    margin-left:30px;
    margin-right:80px
}
</style>