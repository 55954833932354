<template>
  <div>
    <!-- 头部按钮 -->
    <div class="top">
      <div class="left">
        <el-button @click="backClick" class="return">返回</el-button>
        <div class="txt">对账封面</div>
      </div>
      <!-- <el-button @click="cebutton" class="export">测试导出</el-button> -->
      <!-- <el-button @click="handleDownload" class="export">导出</el-button> -->
      <!-- <el-button v-print="printObj" class="export">打印</el-button> -->
      <el-button   @click="printContext" class="export">打印</el-button>
    </div>
    <!-- 表单 -->
    <div class="from" ref="print" id="printTest">
      <div class="title">对 账 清 单</div>
      <div class="from-top">
        <div>结算单位：{{name}}</div>
        <div>结算日期：{{brginTime}} 至 {{endTime}}</div>
      </div>
      <el-table style="width: 100%" :data="tableData">
        <el-table-column label="品牌" prop="goods_brand" align="center"> </el-table-column>
        <el-table-column label="上期结存" align="center">
          <el-table-column label="数量" prop="cq_count" align="center"></el-table-column>
          <el-table-column label="金额" prop="cq_total" align="center"></el-table-column>
        </el-table-column>
        <el-table-column label="本期进货" align="center">
          <el-table-column label="数量" prop="bqrk_count" align="center"></el-table-column>
          <el-table-column label="金额" prop="bqrk_total" align="center"></el-table-column>
        </el-table-column>
        <el-table-column label="本期调入" align="center">
          <el-table-column label="数量" prop="bqdhsh_count" align="center"></el-table-column>
<!--          <el-table-column label="金额" prop="bqdhsh_total" align="center"></el-table-column> -->
        </el-table-column>
		  <el-table-column label="本期调出" align="center">
		    <el-table-column label="数量" prop="bqdhfh_count" align="center"></el-table-column>
<!-- 		    <el-table-column label="金额" prop="bqdhfh_total" align="center"></el-table-column> -->
		  </el-table-column>
        <el-table-column label="本期退货" align="center">
          <el-table-column label="数量" prop="bqck_count" align="center"></el-table-column>
          <el-table-column label="金额" prop="bqck_total" align="center"></el-table-column>
        </el-table-column>
        <el-table-column label="本期销售" align="center">
          <el-table-column label="数量" prop="bqxs_count" align="center"></el-table-column>
          <el-table-column label="金额" prop="bqxs_total" align="center"></el-table-column>
        </el-table-column>
        <el-table-column label="本期库存" align="center">
          <el-table-column label="数量" prop="bqkc_count" align="center"></el-table-column>
          <el-table-column label="金额" prop="bqkc_total" align="center"></el-table-column>
        </el-table-column>
      </el-table>
      <div class="from-bom">
        <div>制单：</div>
        <div>负责人：</div>
        <div style="padding-right: 250px;">对账单位及负责人（盖章）：</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reconciliation} from '@/api/index.js'
export default {
  data() {
    return {
      tableData: [],
      name:'',
      brginTime:'',
      endTime:'',
      tableDatas: [{
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-02',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-04',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-01',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-08',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-06',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }, {
        date: '2016-05-07',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333
      }],

      printObj: {
        id: "printTest",//打印区域 Dom ID
        popTitle: '',
        extraCss: ' ',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>,<style> #printTest { height: auto !important;width:100% !important } <style>'  ,//  可以传进去  style tag 标签；注意要逗号分隔   解决特定区域不显示问题；
      }
      
    };
  },
  created(){
    let data = JSON.parse(this.$route.query.key)
    if(data){
      this.name = data.name
      this.brginTime = data.start
      this.endTime = data.end
      this.getListApi(data)
    }else{
      this.backClick()
      this.$message.error('非法进入');
    }
  },
  methods:{
    // 请求账单接口
    getListApi(data){
      reconciliation(data).then(res=>{
        console.log(res)
        this.tableData = res.data
      })
    },
    backClick(){
      this.$router.go(-1)
    },
    // 导出数据
    handleDownload() {
        import('@/vendor/Export2Excel').then(excel => {
          const multiHeader =
          [
            ['对账清单','', '', '', '', '','', '', '', '', ''],
            ['结算单位:',this.name, '', '', '', '','', '结算日期:', this.brginTime, '至', this.endTime],
            ['日期', '上期结存', '', '本期进货', '', '本期退货','', '本期销售', '', '本期库存', ''],
          //   ['', '姓名', '地址', '', '', ''] //第二行
          ] // 前两行的表头数据，二维数组，不够的用空白补全
          const header = ['', '数量', '金额', '数量', '金额', '数量', '金额', '数量', '金额', '数量', '金额'] // 最后一行的表头数据
          const filterVal = ['goods_brand', 'cq_count', 'cq_total', 'bqrk_count', 'bqrk_total', 'bqck_count','bqck_total','bqxs_count','bqxs_total','bqkc_count','bqkc_total']
          const list = this.tableData
          const data = this.formatJson(filterVal, list)
          console.log(data)
          return
          const merges = ['A1:K1','A3:A4','B3:C3','D3:E3','F3:G3','H3:I3','J3:K3'] // 合并单元格的数据，如何合并参考上面图片的蓝色背景部分
          excel.export_json_to_excel({
            multiHeader,
            header,
            merges,
            data,
            filename:'对账清单',
          })
        })
    },

    // 测试导出
    cebutton(){
      import('@/vendor/Export2Excel').then(excel => {
          const multiHeader =
          [
            ['日期', '配送信息', '', '', '', ''], //第一行
            ['', '姓名', '地址', '', '', ''] //第二行
          ] // 前两行的表头数据，二维数组，不够的用空白补全
          const header = ['', '', '省份', '市区', '地址', '邮编'] // 最后一行的表头数据
          const filterVal = ['date', 'name', 'province', 'city', 'address', 'zip']
          const list = this.tableDatas
          const data = this.formatJson(filterVal, list)
          console.log(data)
          return
          const merges = ['A1:A3', 'B1:F1', 'B2:B3', 'C2:F2'] // 合并单元格的数据，如何合并参考上面图片的蓝色背景部分
          excel.export_json_to_excel({
            multiHeader,
            header,
            merges,
            data
          })
      })
    },
    printContext () {
        this.$print(this.$refs.print)
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    }
  }
};
</script>


<style >
.el-table--border:after,.el-table--group:after,.el-table:before {background-color:  #000;}
.el-table--border,.el-table--group {  border-color: #000;}
.el-table td,.el-table th.is-leaf { border: 2px solid  #000;}
.el-table--border th,.el-table--border th.gutter:last-of-type {border: 2px solid  #000;}
.el-table--border td,.el-table--border th {border: 2px solid  #000;}
.el-table__footer-wrapper{border:  #000 solid 2px;}
</style>

<style scoped>
.top {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 100px;
}
.top .export {
  width: 105px;
  height: 50px;
  background: linear-gradient(90deg, #ff861b, #ffb527);
  box-shadow: 0px 5px 30px 0px rgba(255, 142, 66, 0.4);
  border-radius: 6px;
  font-size: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border:none
}
.top .left {
  display: flex;
  align-items: center;
}
.top .left .return {
  width: 107px;
  height: 44px;
  background: #e6e6e6;
  border-radius: 22px;
  font-size: 24px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border:none
}
.top .left .txt {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
  margin-left: 31px;
}
.from {
  box-sizing: border-box;
  padding: 0 100px;
}
.from .title {
  font-size: 51px;
  font-weight: 500;
  color: #333333;
  line-height: 90px;
  text-align: center;
}
.from .from-top {
  height: 100px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.from .from-bom {
  height: 100px;
  font-size: 30px;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
</style>