import service from '@/utils/request.js'

// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/**************************************
 * 财务核算模块
 **************************************/
/**
 * 获取信息
 */
export function check(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/check/index',
        method: 'get',
        params:data
    })
}

/**
 * 提交
 */
export function checkedit(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/check/edit',
        method: 'post',
        data
    })
}
